import styled from 'styled-components';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import { Bio } from '../../data/constants';

const AnimatedLogo = styled.div`
  display: inline-block;
  cursor: pointer;
`;

const AnimatedNavLink = styled.a`
  color: ${({ theme }) => theme.text_primary};
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
  display: inline-block;
  
  &:hover {
    color: ${({ theme }) => theme.primary};
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const AnimatedSocialMediaIcon = styled.a`
  display: inline-block;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.text_primary};
  transition: color 0.3s ease-in-out, transform 0.3s;
  margin: 0 1rem;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.primary};
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
`;

const FooterWrapper = styled.footer`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  padding: 0.5rem;
  color: ${({ theme }) => theme.text_primary};
`;

const Nav = styled.nav`
  width: 100%;
  max-width: 800px;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 12px;
  }
`;

const Copyright = styled.p`
  margin-top: 1.5rem;
  font-size: 0.9rem;
  color: ${({ theme }) => theme.soft2};
  text-align: center;
`;

function Footer() {
  const handleClick = (e) => {
    // No animation applied
  };

  return (
    <FooterContainer>
      <FooterWrapper>
        <AnimatedLogo onClick={handleClick}>
          <img src="cypercode.png" width="250px" height="250px" alt="CyperCode Logo" />
        </AnimatedLogo>
        <Nav>
          <AnimatedNavLink href="#about" onClick={handleClick}>About</AnimatedNavLink>
          <AnimatedNavLink href="#skills" onClick={handleClick}>Skills</AnimatedNavLink>
          <AnimatedNavLink href="#experience" onClick={handleClick}>Experience</AnimatedNavLink>
          {/* <AnimatedNavLink href="#projects" onClick={handleClick}>Projects</AnimatedNavLink> */}
          <AnimatedNavLink href="https://drive.google.com/file/d/1nQkL1S8a_IxC6Jvyu7-gXEQ9sgpz_BUF/view?usp=sharing" onClick={handleClick}>Design</AnimatedNavLink>
          <AnimatedNavLink href="#education" onClick={handleClick}>Education</AnimatedNavLink>
        </Nav>
        <div>
          <AnimatedSocialMediaIcon href={Bio.facebook} target="display" onClick={handleClick}>
            <FacebookIcon />
          </AnimatedSocialMediaIcon>
          <AnimatedSocialMediaIcon href={Bio.twitter} target="display" onClick={handleClick}>
            <TwitterIcon />
          </AnimatedSocialMediaIcon>
          <AnimatedSocialMediaIcon href={Bio.linkedin} target="display" onClick={handleClick}>
            <LinkedInIcon />
          </AnimatedSocialMediaIcon>
          <AnimatedSocialMediaIcon href={Bio.insta} target="display" onClick={handleClick}>
            <InstagramIcon />
          </AnimatedSocialMediaIcon>
          <AnimatedSocialMediaIcon href={Bio.github} target="display" onClick={handleClick}>
            <GitHubIcon />
          </AnimatedSocialMediaIcon>
        </div>
        <Copyright>
          &copy; 2024 Abhishek Sharma. All rights reserved.
        </Copyright>
      </FooterWrapper>
    </FooterContainer>
  );
}

export default Footer;
