import React from 'react';
import styled from 'styled-components'
import CountUp from 'react-countup';
import { count } from '../../data/constants';

const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
position: relative;
z-index: 1;
align-items: center;
`

const Wrapper = styled.div`
position: relative;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
width: 100%;
max-width: 1100px;
gap: 12px;
@media (max-width: 960px) {
    flex-direction: column;
}
`

export const Title = styled.div`
font-size: 42px;
text-align: center;
font-weight: 600;
margin-top: 130px;
  color: ${({ theme }) => theme.text_primary};
  @media (max-width: 768px) {
margin-top: 100px;
      font-size: 32px;
  }
`;

export const Desc = styled.div`
    font-size: 18px;
    text-align: center;
    max-width: 600px;
    color: ${({ theme }) => theme.text_secondary};
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const SkillsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  gap: 30px;
  justify-content: center;
`

const Skill = styled.div`
  width: 100%;
  max-width: 1000px;
  background: ${({ theme }) => theme.card};
  border: 0.1px solid #854CE6;
  box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
  border-radius: 16px;
  padding: 18px 36px;
  @media (max-width: 768px) {
    max-width: 400px;
    padding: 10px 36px;
  }
  @media (max-width: 500px) {
    max-width: 330px;
    padding: 10px 36px;
  }


`

const SkillTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  color: ${({ theme }) => theme.text_secondary};
  margin-bottom: 20px;
  text-align: center;
`

const SkillList = styled.div`
  display: flex;
  justify-content: center; 
  flex-wrap: wrap;
  gap: 12px;
  
`

const SkillItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.text_primary + 80};
  border: 1px solid ${({ theme }) => theme.text_primary + 80};
  border-radius: 12px;
  padding: 12px 16px;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 12px;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    padding: 6px 12px;
  }
`;

const CountUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CountUpWrapper = styled.div`
  font-size: 43px; 
  font-weight: 600;
  color: ${({ theme }) => theme.text_primary};
  text-align: center;
`;

const SideText = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.text_secondary};
  margin-top: 8px;
`;


const Counter12 = () => {
  return (
    <Container id="count">
      <Wrapper>
        <Title>Success</Title>
        <Desc>Here is the client satisfaction data from the past 2 years.</Desc>
        <SkillsContainer>
          <Skill>
            <SkillList>
              <SkillItem>
                <CountUpContainer>
                  <CountUpWrapper>
                    <CountUp start={1} end={150} formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })} />
                    +
                  </CountUpWrapper>
                  <SideText>Projects</SideText>
                </CountUpContainer>
              </SkillItem>
              <SkillItem>
                <CountUpContainer>
                  <CountUpWrapper>
                    <CountUp start={1} end={1000} formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })} />
                    +
                  </CountUpWrapper>
                  <SideText>Active Clients</SideText>
                </CountUpContainer>
              </SkillItem>
              <SkillItem>
                <CountUpContainer>
                  <CountUpWrapper>
                    <CountUp start={1} end={15} formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })} />
                    +
                  </CountUpWrapper>
                  <SideText>Certification</SideText>
                </CountUpContainer>
              </SkillItem>
              <SkillItem>
                <CountUpContainer>
                  <CountUpWrapper>
                    <CountUp start={1} end={80} formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })} />
                    +
                  </CountUpWrapper>
                  <SideText>Websites & Apps</SideText>
                </CountUpContainer>
              </SkillItem>
              <SkillItem>
                <CountUpContainer>
                  <CountUpWrapper>
                    <CountUp start={1} end={90} formattingFn={(value) => value.toLocaleString('en-US', { useGrouping: false })}/>
                    %
                  </CountUpWrapper>
                  <SideText>Client Satisfied</SideText>
                </CountUpContainer>
              </SkillItem>
            </SkillList>
          </Skill>
        </SkillsContainer>
      </Wrapper>
    </Container>
  );
};

export default Counter12;
