import React, { useState, useEffect } from 'react';
import { FaRegArrowAltCircleUp } from 'react-icons/fa';

const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      setShowButton(window.scrollY > 400);
    };

    window.addEventListener('scroll', checkScrollHeight);

    return () => {
      window.removeEventListener('scroll', checkScrollHeight);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {showButton && (
        <div
          onClick={scrollToTop}
          style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            color: 'white',
            padding: '10px',
            cursor: 'pointer',
            borderRadius: '6px',
            transition: 'all 0.2s ease-in-out!important',
            background: 'linear-gradient(225deg, hsla(271, 100%, 50%, 1) 0%, hsla(294, 100%, 50%, 1) 100%)',
            boxShadow: '20px 20px 60px #1F2634, -20px -20px 60px #1F2634',
            ':hover': {
              transform: 'scale(1.05)',
              transition: 'all 0.4s ease-in-out',
              boxShadow: '20px 20px 60px #1F2634',
              filter: 'brightness(1)',
            },
          }}
        >
          <FaRegArrowAltCircleUp />
        </div>
      )}
    </div>
  );
};

export default BackToTop;
